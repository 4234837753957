import api from '../api';
import { storableError } from '../util/errors';
import { DEFAULT_AVAILABILITY_PLAN } from '../util/listingHelpers';
import { LISTING_STATE_CLOSED, LISTING_STATE_PUBLISHED } from '../util/types';
import {
  addMarketplaceEntities,
  getListingsById,
  getMarketplaceEntities,
} from './marketplaceData.duck';

// ================ Action types ================ //

export const DUPLICATE_LISTING_REQUEST = 'app/OwnListing/DUPLICATE_LISTING_REQUEST';
export const DUPLICATE_LISTING_SUCCESS = 'app/OwnListing/DUPLICATE_LISTING_SUCCESS';
export const DUPLICATE_LISTING_ERROR = 'app/OwnListing/DUPLICATE_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/OwnListing/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/OwnListing/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/OwnListing/CLOSE_LISTING_ERROR';

export const OPEN_LISTING_REQUEST = 'app/OwnListing/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/OwnListing/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/OwnListing/OPEN_LISTING_ERROR';

export const DELETE_LISTING_REQUEST = 'app/OwnListing/DELETE_LISTING_REQUEST';
export const DELETE_LISTING_SUCCESS = 'app/OwnListing/DELETE_LISTING_SUCCESS';
export const DELETE_LISTING_ERROR = 'app/OwnListing/DELETE_LISTING_ERROR';

// ================ Reducer ================ //

const getOwnListing = (id, state) => {
  const ref = { id, type: 'ownListing' };
  const listings = getMarketplaceEntities(state, [ref]);

  return listings.length === 1 ? listings : null;
};

const getListingWithAttributes = (listing, listingState, metadata) => ({
  ...listing,
  attributes: {
    ...listing.attributes,
    state: listingState || listing.attributes.state,
    metadata: { ...listing.attributes.metadata, ...metadata },
  },
});

const getUpdatedListing = (listingId, listingState, metadata, getState) => {
  const state = getState();

  const publicListingToUpdate = getListingsById(state, [listingId]);

  // This means its an ownListing
  const ownListingToUpdate = getOwnListing(listingId, state);

  let publicListing = [];
  let ownListing = [];

  if (publicListingToUpdate?.length) {
    const listing = publicListingToUpdate[0];

    publicListing = [getListingWithAttributes(listing, listingState, metadata)];
  }

  if (ownListingToUpdate?.length) {
    const listing = ownListingToUpdate[0];

    ownListing = [getListingWithAttributes(listing, listingState, metadata)];
  }

  return [...publicListing, ...ownListing];
};

const initialState = {
  duplicateInProgress: false,
  duplicateError: null,
  closeInProgress: false,
  closeError: null,
  openInProgress: false,
  openError: null,
  deleteInProgress: false,
  deleteError: null,
};

export default function listingsReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case DUPLICATE_LISTING_REQUEST:
      return { ...state, duplicateInProgress: true, duplicateError: null };
    case DUPLICATE_LISTING_SUCCESS:
      return { ...state, duplicateInProgress: false };
    case DUPLICATE_LISTING_ERROR:
      return { ...state, duplicateInProgress: false, duplicateError: payload };

    case CLOSE_LISTING_REQUEST:
      return { ...state, closeInProgress: true, closeError: null };
    case CLOSE_LISTING_SUCCESS:
      return { ...state, closeInProgress: false };
    case CLOSE_LISTING_ERROR:
      return { ...state, closeInProgress: false, closeError: payload };

    case OPEN_LISTING_REQUEST:
      return { ...state, openInProgress: true, openError: null };
    case OPEN_LISTING_SUCCESS:
      return { ...state, openInProgress: false };
    case OPEN_LISTING_ERROR:
      return { ...state, openInProgress: false, openError: payload };

    case DELETE_LISTING_REQUEST:
      return { ...state, deleteInProgress: true, deleteError: null };
    case DELETE_LISTING_SUCCESS:
      return { ...state, deleteInProgress: false };
    case DELETE_LISTING_ERROR:
      return { ...state, deleteInProgress: false, deleteError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const duplicateListingRequest = () => ({ type: DUPLICATE_LISTING_REQUEST });
const duplicateListingSuccess = () => ({ type: DUPLICATE_LISTING_SUCCESS });
const duplicateListingError = error => ({
  type: DUPLICATE_LISTING_ERROR,
  error: true,
  payload: error,
});

const closeListingRequest = () => ({ type: CLOSE_LISTING_REQUEST });
const closeListingSuccess = payload => ({ type: CLOSE_LISTING_SUCCESS, payload });
const closeListingError = error => ({ type: CLOSE_LISTING_ERROR, error: true, payload: error });

const openListingRequest = () => ({ type: OPEN_LISTING_REQUEST });
const openListingSuccess = payload => ({ type: OPEN_LISTING_SUCCESS, payload });
const openListingError = error => ({ type: OPEN_LISTING_ERROR, error: true, payload: error });

const deleteListingRequest = () => ({ type: DELETE_LISTING_REQUEST });
const deleteListingSuccess = payload => ({ type: DELETE_LISTING_SUCCESS, payload });
const deleteListingError = error => ({ type: DELETE_LISTING_ERROR, error: true, payload: error });

// ================ Thunks ================ //

export const duplicateListing = listingId => (dispatch, getState, sdk) => {
  dispatch(duplicateListingRequest());

  return sdk.ownListings
    .show({ id: listingId })
    .then(res => {
      const listing = res.data.data;
      const {
        createdAt,
        deleted,
        metadata,
        state,
        price,
        availabilityPlan,
        publicData,
        privateData,
        ...copyData
      } = listing.attributes;

      return sdk.ownListings
        .createDraft(
          {
            ...copyData,
            availabilityPlan,
            publicData,
            privateData,
            price,
          },
          { expand: true }
        )
        .then(response => {
          return api.listings.getListingPeriods(listingId?.uuid).then(data => {
            const periods = data?.data?.periods.length
              ? data?.data?.periods.map(dates => ({
                  startsAt: dates?.startsAt,
                  endsAt: dates?.endsAt,
                }))
              : [];

            return api.listings
              .updateListingPeriods(response?.data?.data?.id?.uuid, periods)
              .then(res => {
                dispatch(duplicateListingSuccess());
                return response;
              });
          });
        });
    })
    .catch(e => {
      console.error(e, 'duplicate-listing-fetch-own-listing-faild', { listingId });
      return dispatch(duplicateListingError(storableError(e)));
    });
};

export const closeListing = listingId => (dispatch, getState) => {
  dispatch(closeListingRequest());

  return api.listings
    .closeListing(listingId.uuid)
    .then(() => {
      dispatch(closeListingSuccess());

      const updatedListing = getUpdatedListing(listingId, LISTING_STATE_CLOSED, {}, getState);

      dispatch(addMarketplaceEntities({ data: { data: updatedListing } }));
    })
    .catch(() => dispatch(closeListingError()));
};

export const openListing = listingId => (dispatch, getState) => {
  dispatch(openListingRequest());

  return api.listings
    .openListing(listingId.uuid)
    .then(() => {
      dispatch(openListingSuccess());

      const updatedListing = getUpdatedListing(listingId, LISTING_STATE_PUBLISHED, {}, getState);

      dispatch(addMarketplaceEntities({ data: { data: updatedListing } }));
    })
    .catch(() => dispatch(openListingError()));
};

export const deleteListing = listingId => (dispatch, getState) => {
  dispatch(deleteListingRequest());

  return api.listings
    .requestListingDeletion(listingId.uuid)
    .then(() => {
      dispatch(deleteListingSuccess());

      const updatedListing = getUpdatedListing(
        listingId,
        null,
        {
          hasRequestedDeletion: true,
        },
        getState
      );

      dispatch(addMarketplaceEntities({ data: { data: updatedListing } }));
    })
    .catch(() => dispatch(deleteListingError()));
};
